import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Paper, Typography } from '@mui/material';

const CurrentWeather = ({ city }) => {
    const { current, loading, error } = useSelector((state) => state.weather);
    const [time, setTime] = useState(new Date().toLocaleTimeString());

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date().toLocaleTimeString());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const getDisplayCityName = (city) => {
        if (!city || /^\d/.test(city)) {
            return "Location";
        }
        return city;
    };

    if (loading) return <Typography className="loading">Loading...</Typography>;
    if (error) return <Typography className="loading">Error: {error}</Typography>;
    if (!current) return <Typography className="loading"></Typography>;

    return (
        <Paper elevation={3} sx={{ padding: 2, width: '100%' }}>
            <Typography variant="h4" gutterBottom>
                Current Weather in {getDisplayCityName(city)}
            </Typography>
            <Typography variant="h6">Temperature: {current.main.temp}°F</Typography>
            <Typography variant="h6">Condition: {current.weather[0].description}</Typography>
            <Typography variant="h6">Current Time: {time}</Typography>
        </Paper>
    );
};

export default CurrentWeather;