import axios from 'axios';

const backendUrl = 'https://api.globalskyforecast.com';

export const fetchWeather = (value, country, type = 'zip') => async (dispatch) => {
    dispatch({ type: 'FETCH_WEATHER_REQUEST' });
    try {
        const decodedValue = decodeURIComponent(value);
        const [currentResponse, forecastResponse] = await Promise.all([
            axios.get(`${backendUrl}/weather`, { params: { [type]: decodedValue, country } }),
            axios.get(`${backendUrl}/forecast`, { params: { [type]: decodedValue, country } })
        ]);

        const city = currentResponse.data.city || currentResponse.data.name;
        const currentDateTime = new Date();

        // Include only unique data points for today starting from the current time
        const futureForecast = forecastResponse.data.list.filter((item, index, self) => {
            const forecastDateTime = new Date(item.dt_txt);
            return (
                forecastDateTime >= currentDateTime &&
                index === self.findIndex((i) => i.dt_txt === item.dt_txt)
            );
        });

        const combinedForecast = futureForecast;

        dispatch({
            type: 'FETCH_WEATHER_SUCCESS',
            payload: { current: currentResponse.data, forecast: combinedForecast, city },
        });
    } catch (error) {
        console.error('Error fetching weather data:', error);
        dispatch({ type: 'FETCH_WEATHER_FAILURE', payload: error.message });
    }
};


