import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWeather } from './actions/weatherActions';
import CurrentWeather from './components/CurrentWeather';
import WeatherTable from './components/WeatherTable';
import {
    Container,
    Typography,
    Box,
    AppBar,
    Toolbar,
    TextField,
    Button,
    MenuItem,
    Select,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    IconButton,
    Menu,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './index.css';

const DEFAULT_ZIP_CODE = '10001'; // NYC zip code
const DEFAULT_COUNTRY = 'US';

const App = () => {
    const dispatch = useDispatch();
    const { current, loading, error, city } = useSelector((state) => state.weather);
    const [inputZipCode, setInputZipCode] = useState(localStorage.getItem('preferredZipCode') || DEFAULT_ZIP_CODE);
    const [inputCountry, setInputCountry] = useState(localStorage.getItem('preferredCountry') || DEFAULT_COUNTRY);
    const [zipCode, setZipCode] = useState(inputZipCode);
    const [country, setCountry] = useState(inputCountry);
    const [useLocation, setUseLocation] = useState(false);
    const [locationLoading, setLocationLoading] = useState(false); // This is the state you mentioned
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        dispatch(fetchWeather(zipCode, country));
    }, [dispatch, zipCode, country]);

    useEffect(() => {
        if (useLocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`);
                const data = await response.json();
                setInputZipCode(data.postcode);
                setInputCountry(data.countryCode || 'US');
                setZipCode(data.postcode);
                setCountry(data.countryCode || 'US');
                localStorage.setItem('preferredZipCode', data.postcode);
                localStorage.setItem('preferredCountry', data.countryCode || 'US');
                dispatch(fetchWeather(data.postcode, data.countryCode || 'US'));
                setUseLocation(false);
                setLocationLoading(false);
            }, () => {
                setUseLocation(false);
                setLocationLoading(false);
                setOpenDialog(true);
            });
        }
    }, [dispatch, useLocation]);

    const getWeatherClass = (weather) => {
        if (!weather) return '';
        const condition = weather.weather[0].main.toLowerCase().replace(' ', '-');
        return condition;
    };

    const getTimeClass = () => {
        const hour = new Date().getHours();
        return hour >= 18 || hour < 6 ? 'night' : 'day';
    };

    const weatherClass = getWeatherClass(current);
    const timeClass = getTimeClass();

    const handleSearch = () => {
        setUseLocation(false);
        if (inputCountry === 'JP') {
            dispatch(fetchWeather(inputZipCode, inputCountry, 'location'));
        } else {
            const encodedZipCode = encodeURIComponent(inputZipCode.trim());
            setZipCode(encodedZipCode);
            setCountry(inputCountry);
            localStorage.setItem('preferredZipCode', encodedZipCode);
            localStorage.setItem('preferredCountry', inputCountry);
            dispatch(fetchWeather(encodedZipCode, inputCountry));
        }
        handleMenuClose();
    };

    const handleUseMyLocation = () => {
        setUseLocation(true);
        setLocationLoading(true);
        handleMenuClose();
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleRetryLocation = () => {
        setOpenDialog(false);
        handleUseMyLocation();
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const menuItems = (
        <>
            <MenuItem>
                <Select
                    value={inputCountry}
                    onChange={(e) => {
                        setUseLocation(false);
                        setInputCountry(e.target.value);
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{ backgroundColor: 'white', borderRadius: 1 }}
                >
                    <MenuItem value="US">United States</MenuItem>
                    <MenuItem value="JP">Japan</MenuItem>
                    <MenuItem value="CA">Canada</MenuItem>
                    <MenuItem value="GB">United Kingdom</MenuItem>
                    <MenuItem value="FR">France</MenuItem>
                    <MenuItem value="DE">Germany</MenuItem>
                    {/* Add more countries as needed */}
                </Select>
            </MenuItem>
            <MenuItem>
                <TextField
                    label="Zip Code"
                    variant="outlined"
                    value={inputZipCode}
                    onChange={(e) => {
                        setUseLocation(false);
                        setInputZipCode(e.target.value);
                    }}
                    onKeyPress={handleKeyPress}
                    sx={{ backgroundColor: 'white', borderRadius: 1 }}
                />
            </MenuItem>
            <MenuItem>
                <Button variant="contained" color="primary" onClick={handleSearch}>
                    Search
                </Button>
            </MenuItem>
            <MenuItem>
                <Button variant="contained" color="secondary" onClick={handleUseMyLocation} startIcon={<MyLocationIcon />}>
                    Use My Location
                </Button>
            </MenuItem>
        </>
    );

    return (
        <div id="app" className={`${weatherClass} ${timeClass}`}>
            <AppBar position="fixed" color="primary">
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Global Sky Forecast
                    </Typography>
                    {isMobile ? (
                        <>
                            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                {menuItems}
                            </Menu>
                        </>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Select
                                value={inputCountry}
                                onChange={(e) => {
                                    setUseLocation(false);
                                    setInputCountry(e.target.value);
                                }}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{ backgroundColor: 'white', borderRadius: 1 }}
                            >
                                <MenuItem value="US">United States</MenuItem>
                                <MenuItem value="JP">Japan</MenuItem>
                                <MenuItem value="CA">Canada</MenuItem>
                                <MenuItem value="GB">United Kingdom</MenuItem>
                                <MenuItem value="FR">France</MenuItem>
                                <MenuItem value="DE">Germany</MenuItem>
                                {/* Add more countries as needed */}
                            </Select>
                            <TextField
                                label="Zip Code"
                                variant="outlined"
                                value={inputZipCode}
                                onChange={(e) => {
                                    setUseLocation(false);
                                    setInputZipCode(e.target.value);
                                }}
                                onKeyPress={handleKeyPress}
                                sx={{ backgroundColor: 'white', borderRadius: 1 }}
                            />
                            <Button variant="contained" color="primary" onClick={handleSearch}>
                                Search
                            </Button>
                            <Button variant="contained" color="secondary" onClick={handleUseMyLocation} startIcon={<MyLocationIcon />}>
                                Use My Location
                            </Button>
                        </Box>
                    )}
                </Toolbar>
            </AppBar>
            <div className="content">
                <Container maxWidth="md">
                    {loading || locationLoading ? ( // Use locationLoading state
                        <div>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <CurrentWeather city={city} />
                            <WeatherTable />
                        </>
                    )}
                    {error && <Typography variant="h6" color="error">Error: {error}</Typography>}
                </Container>
            </div>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Location Access Denied"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Location access is required to fetch weather data for your current location.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRetryLocation} color="primary" autoFocus>
                        Retry
                    </Button>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default App;
