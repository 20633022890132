const initialState = {
    current: null,
    forecast: [],
    city: '',
    loading: false,
    error: null,
};

export default function weatherReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_WEATHER_REQUEST':
            return { ...state, loading: true, error: null };
        case 'FETCH_WEATHER_SUCCESS':
            return { ...state, loading: false, current: action.payload.current, forecast: action.payload.forecast, city: action.payload.city };
        case 'FETCH_WEATHER_FAILURE':
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}