import React from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import CloudIcon from '@mui/icons-material/Cloud';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import OpacityIcon from '@mui/icons-material/Opacity';
import GrainIcon from '@mui/icons-material/Grain';

const getWeatherIcon = (condition) => {
    switch (condition) {
        case 'clear sky':
            return <WbSunnyIcon />;
        case 'few clouds':
        case 'scattered clouds':
        case 'broken clouds':
        case 'overcast clouds':
            return <CloudIcon />;
        case 'shower rain':
        case 'rain':
        case 'light rain':
        case 'moderate rain':
            return <OpacityIcon />;
        case 'snow':
            return <AcUnitIcon />;
        case 'mist':
            return <GrainIcon />;
        default:
            return <WbSunnyIcon />;
    }
};

const WeatherTable = () => {
    const { forecast, loading, error } = useSelector((state) => state.weather);

    if (loading) return <Typography className="loading">Loading...</Typography>;
    if (error) return <Typography className="loading">Error: {error}</Typography>;
    if (!forecast.length) return <Typography className="loading">No forecast data available</Typography>;

    const groupedForecast = forecast.reduce((acc, item) => {
        const date = new Date(item.dt_txt).toDateString();
        if (!acc[date]) acc[date] = [];
        acc[date].push(item);
        return acc;
    }, {});

    return (
        <Box sx={{ width: '100%', mt: 4 }} className="table-container">
            <Typography variant="h5" gutterBottom>
                Forecast
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Time</TableCell>
                            <TableCell>Condition</TableCell>
                            <TableCell>Temp (°F)</TableCell>
                            <TableCell>Feels Like (°F)</TableCell>
                            <TableCell>Precip</TableCell>
                            <TableCell>Cloud Cover</TableCell>
                            <TableCell>Humidity</TableCell>
                            <TableCell>Wind</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(groupedForecast).map((date) => (
                            <React.Fragment key={date}>
                                <TableRow>
                                    <TableCell colSpan={8} style={{ backgroundColor: '#f0f0f0' }}>
                                        <Typography variant="h6">{date}</Typography>
                                    </TableCell>
                                </TableRow>
                                {groupedForecast[date].map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{new Date(item.dt_txt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                {getWeatherIcon(item.weather[0].description)}
                                                {item.weather[0].description}
                                            </Box>
                                        </TableCell>
                                        <TableCell>{item.main.temp}°F</TableCell>
                                        <TableCell>{item.main.feels_like}°F</TableCell>
                                        <TableCell>{item.pop ? `${item.pop * 100}%` : '0%'}</TableCell>
                                        <TableCell>{item.clouds.all}%</TableCell>
                                        <TableCell>{item.main.humidity}%</TableCell>
                                        <TableCell>{item.wind.speed} mph</TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default WeatherTable;
